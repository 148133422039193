:root {
    --blue: hsl(210, 88%, 56%);
    --grey: hsl(216,2%,59%);
    --grey-light: hsl(210,2%,53%);
    --grey-lighter: hsl(0,0%,85%);
    --keyboard-bg: hsl(214,9%,84%);
    --grey-orange: hsl(32, 97%, 55%);
    --orange-light: hsl(34, 76%, 89%);
    --green: hsl(135, 80%, 28%);
    --green-dark: hsl(135, 53%, 24%);
}

* {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    overflow: hidden;
}

body {
    background: var(--background-color);
    color: var(--blue);
    line-height: bold;
    height: 100%;
    font-family: "Clear Sans", "Helvetica Neue", Arial, sans-serif;
}

.container {
    display: flex;
    margin: 0px auto;
    width: 100vw;
    height: 95vh;
    color: black;
    flex-direction: column;
}

.header {
    display: grid;
    grid-template-columns: auto auto auto auto;
    justify-content: space-between;
    align-items: center;
    height: 55px;
    border-bottom: 1px solid var(--grey-light);
    color: var(--blue);
    padding: 5px 0px 0px 0px;
    margin-bottom: auto;
}

.title {
    font-weight: 700;
    font-size: 30px;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    text-align: center;
    pointer-events: none;
}

.puzzle_number {
    padding-top: 0.8em;
    margin-left: 1em;
}

button#share_button {
    background: url(./assets/share_icon.svg) no-repeat center;
    height: 2.5em;
    width: 2.5em;
    border-radius: 50%;
    cursor: pointer;
    border: 0;
    margin-right: 1em;
    margin-left: -1em;
}


button#help {
    background: url(./assets/help_icon.svg) no-repeat center;
    height: 1.5em;
    width: 1.5em;
    border: 0px;
    margin-right: 1em;
    cursor: pointer;
}

.game_board {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    max-width: 200px;
    width: 100%;
    padding-bottom: 10px;
}

.game_cell {
    position: relative;
    flex-basis: calc(20% - 8px);
    margin: 4px;
    box-sizing: border-box;
    border: 2px solid var(--grey-lighter);
    font-weight: bold;
    font-size: 1.3em;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: black;
}

.game_cell::before {
    content: "";
    display: block;
    padding-top: 100%;
}

.game_cell.userfilled {
    border: 2px solid var(--grey);
}

.game_cell.current {
    cursor: pointer;
}

.game_cell.highlight {
    background-color: var(--blue);
}

.game_cell.correct {
    background-color: var(--green);
    color: var(--grey-lighter);
}

.keyboard {
    margin: auto;
    width: 100%;
}

.keys_row {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.keyboard_key {
    display: flex;
    border: 0;
    background-color: var(--keyboard-bg);
    width: 3em;
    height: 3.5em;
    min-width: 1.5em;
    min-height: 2em;
    padding: 1.1em 0.5em;
    cursor: pointer;
    border-radius: 4px;
    margin: 3px 3px;
    font-weight: bold;
    justify-content: center;
}

.keyboard_correct {
    background-color: var(--green-dark);
    color: var(--grey-lighter);
}
.keyboard_enter {
    min-width: 5.2em;
}

.copy_success {
    display: block;
    z-index: 1;
}

.modal_container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    max-width: 600px;
}

.modal {
    z-index: 4;
    width: 100%;
    height: 100%;
    background: white;
    text-align: center;
    border-radius: 5px;
    position: relative;
    animation: zoom 0.3s;
    padding: 0 20px;
    overflow-y: auto;
}

.modal h2 {
    margin-bottom: 0;
}

.modal_container button {
    position: absolute;
    top: 7px;
    right: 7px;
    padding: 8px 11px;
    color: var(--grey);
    font-weight: bold;
    border: none;
    outline: none;
    border-radius: 2px;
    cursor: pointer;
}

.instructions {
    text-align: left;
    font-size: 14px;
    color: var(--grey);
    font-weight: light;
}

.examples {
    border-bottom: 1px solid var(--grey-light);
    border-top: 1px solid var(--grey-light);
}

.example .game_board {
    float: left;
    padding: 0;
}

.explainer {
    padding-bottom: 1em;
    width: 100%;
}

.toast_container {
    font-weight: bold;
    text-align: center;
}

.solution_toast {
    text-transform:uppercase;
    text-align: center;
    font-weight: bolder;
    color: var(--green-dark);
    letter-spacing: 0.1em;
    font-size: 1.2em;
    background-color: var(--orange-light);
}

/* animations */

.shake {
    -webkit-animation-name: shake;
    animation-name: shake;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes shake {
    0%,
    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    10%,
    50%,
    90% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0);
    }
    30%,
    70% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0);
    }
}

@keyframes shake {
    0%,
    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    10%,
    50%,
    90% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0);
    }
    30%,
    70% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0);
    }
}

@keyframes zoom {
    from {
        transform: scale(0.5);
    }
    to {
        transform: scale(1);
    }
}
